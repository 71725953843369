import {mapMutations, mapActions, mapGetters} from "vuex";
import {dynamicHead} from "../../mixins/dynamic-head";

//sections


export default {
  name: "contacts",
  components: {},
  mixins: [dynamicHead],
  data() {
    return {
      crumbs: [
        {src: 'home', title: this.$t('home.title'), slug: '/'},
        {src: 'articles', title: this.$t('contacts.title'), slug: 'articles'}
      ],
      checkedMenu:'hangar'
    }
  },
  mounted() {
    this.getContacts()
    this.getPageContent('contacts').then(()=>{
      this.setPageItem(this.pageContent)
      document.title = `${this.pageContent.title} | karbosnab`
    })
  },
  created() {
    this.setRequestFlag(true);
  },
  computed: {
    ...mapGetters({
      contacts: 'contacts/contacts',
      pageContent: 'setting/pageContent',
      globalRequestsFlag: 'system/globalRequestsFlag'
    })
  },

  methods: {
    ...mapActions({
      getContacts: 'contacts/GET_CONTACTS',
      getPageContent: 'setting/GET_PAGE_CONTENT'
    }),
    ...mapMutations({
      setRequestFlag: 'system/SET_REQUESTS_FLAG',
    }),

  }
}
